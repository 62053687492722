import { css } from '@emotion/react';
import theme from '@styles/common/theme';

export const header = css`
  position: fixed;
  position: -webkit-fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 10;
  isolation: isolate;

  width: 100%;
  max-width: 100vw;
  height: 3.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  transition: box-shadow 0.6s, background-color 0.6s;

  &.scrolled {
    background-color: white;
    box-shadow: 0 5px 8px -6px ${theme.grey900};
  }

  &.mobile-menu {
    background-color: white;
    border-bottom: 1px solid ${theme.grey50};
  }
`;

export const headerTopBg = css`
  height: 3.75rem;
  background-color: ${theme.teal500};
`;

export const headerItemsWrapper = css`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const logo = css`
  height: 32px;

  &:focus-visible {
    outline-offset: 4px;
  }

  @media (max-width: 768px) {
    height: 25px;
  }
`;

export const navbar = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-left: auto;
`;

export const desktopLink = css`
  height: 1.875rem;
  font-size: 15px;

  transition: border-bottom 0.6s;

  a {
    border-radius: 0.375rem;
    height: 1.875rem;
    padding: 0 0.9rem;
    color: inherit;
    cursor: inherit;
    font-family: inherit;
    text-decoration: none;

    &:focus {
      outline: none;
      padding: 1rem inherit;
      background-color: ${theme.yellow400} !important;
    }

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    span {
      box-shadow: none;
      transition: box-shadow 0.6s;
      padding: 0.4rem 0;
    }
  }

  &.disabled {
    color: ${theme.grey600};
    cursor: not-allowed;
    padding: 0.4rem 0.9rem;
  }

  :hover:not(.disabled) {
    cursor: pointer;
    span {
      border-bottom: 1px solid ${theme.grey900};
    }
  }

  span {
    box-shadow: none;
  }

  span.active {
    border-bottom: 1px solid ${theme.grey900};
    box-shadow: rgb(40, 50, 55) 0px 1px 0px 0px;
  }
`;

export const loginButton = css`
  margin-left: 1rem;
`;

export const mobileMenuIcon = css`
  margin-left: 1rem;

  all: unset;

  &:focus {
    outline-offset: 4px;
    outline: 2px solid ${theme.yellow400} !important;
    border-radius: 0.2rem;
  }
`;

export const mobileMenuWrapper = css`
  position: fixed;
  top: 0;
  overflow: hidden;
  isolation: isolate;
  z-index: 20;

  width: 100vw;
  height: 100vh;
  padding: 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background: white;

  border: 1px solid black;
`;

export const mobileHeaderItemsWrapper = css`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;
`;

export const topBarMobile = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const divider = css`
  height: 1px;
  width: 100%;
  border: 1px solid ${theme.grey50};
`;

export const mobileLink = css`
  font-weight: 600;
  font-size: 22px;

  padding: 0.9375rem 0;

  a {
    color: inherit;
  }
`;
