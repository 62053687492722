import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const useGoogleTagManager = () => {
  const [isAnalyticsCookieAllowed, setAnalyticsCookieAllowed] = useState(false);
  const [isMarketingCookieAllowed, setMarketingCookieAllowed] = useState(false);

  typeof window !== 'undefined' &&
    window.cookieStore?.addEventListener('change', () => {
      const analyticsAllowed = Boolean(Cookies.get('analyticsCookieConsent'));
      const marketingAllowed = Boolean(Cookies.get('marketingCookieConsent'));

      setAnalyticsCookieAllowed(analyticsAllowed);
      setMarketingCookieAllowed(marketingAllowed);
    });

  useEffect(() => {
    if (!isAnalyticsCookieAllowed) return;

    window.dataLayer.push({
      event: 'cookieConsentUpdate',
      AnalyticsCookieConsent: isAnalyticsCookieAllowed,
      MarketingCookieConsent: isMarketingCookieAllowed,
    });

    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.href,
        title: document.title,
      },
    });
  }, [isAnalyticsCookieAllowed, isMarketingCookieAllowed]);
};

export default useGoogleTagManager;
