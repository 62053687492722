import { useRef } from 'react';

const useScrollBehavior = (children) => {
  const newPath = children?.props?.path;
  const previousPath = useRef(newPath);
  let scrollBehavior;

  if (typeof previousPath.current === 'string' && previousPath.current === newPath) {
    scrollBehavior = 'smooth';
  } else {
    scrollBehavior = 'auto';
  }
  previousPath.current = newPath;

  return scrollBehavior;
};

export default useScrollBehavior;
