/* eslint-disable react/prop-types */
import { css } from '@emotion/react';

import WallopBold from '@static/fonts/wallop/Wallop-Bold.woff2';
import WallopHeavy from '@static/fonts/wallop/Wallop-Heavy.woff2';
import WallopLight from '@static/fonts/wallop/Wallop-Light.woff2';
import WallopMedium from '@static/fonts/wallop/Wallop-Medium.woff2';
import WallopRegular from '@static/fonts/wallop/Wallop-Regular.woff2';
import WallopSemiBold from '@static/fonts/wallop/Wallop-SemiBold.woff2';
import WallopThin from '@static/fonts/wallop/Wallop-Thin.woff2';

export const fontWrapper = css`
  @font-face {
    font-family: 'Wallop';
    src: url(${WallopThin}) format('woff2');
    font-weight: 100;
  }

  @font-face {
    font-family: 'Wallop';
    src: url(${WallopLight}) format('woff2');
    font-weight: 200;
  }

  @font-face {
    font-family: 'Wallop';
    src: url(${WallopRegular}) format('woff2');
    font-weight: normal;
  }
  @font-face {
    font-family: 'Wallop';
    src: url(${WallopRegular}) format('woff2');
    font-weight: 300;
  }
  @font-face {
    font-family: 'Wallop';
    src: url(${WallopMedium}) format('woff2');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Wallop';
    src: url(${WallopSemiBold}) format('woff2');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Wallop';
    src: url(${WallopBold}) format('woff2');
    font-weight: bold;
  }
  @font-face {
    font-family: 'Wallop';
    src: url(${WallopBold}) format('woff2');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Wallop';
    src: url(${WallopHeavy}) format('woff2');
    font-weight: 700;
  }
`;

export const wrapper = css`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const contentWrapper = css`
  isolation: isolate;

  width: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;

export const main = css`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
