/* eslint-disable react-hooks/exhaustive-deps */
import { trackPageView } from '@utils/appinsights-service';
import { useEffect } from 'react';

const usePageViewLog = (pageName) => {
  useEffect(() => {
    trackPageView(pageName);
  }, []);
};

export default usePageViewLog;
