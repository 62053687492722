import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';

import HeaderButton from '@components/common/HeaderButton/HeaderButton';
import BurgerMenu from '@components/common/Icons/BurgerMenu';
import CloseIcon from '@components/common/Icons/Close';
import Logo from '@components/common/Logo/Logo';
import SelectLink from '@components/common/SelectLink/SelectLink';
import StyledLink from '@components/common/StyledLink/StyledLink';

import { trackEvent } from '@utils/appinsights-service';

import * as style from './Header.style';

const LogoLink = ({ closeMenu, scrolled }) => (
  <SelectLink
    to="/"
    data-cy="logo"
    ariaLabel="NewPay Logo - click to navigate back to main page"
    linkStyle={style.logo}
    handleClick={() => {
      closeMenu && closeMenu();
      trackEvent('Logo click');
    }}
  >
    <Logo light={!scrolled} />
  </SelectLink>
);

const LoginButton = () => (
  <HeaderButton
    link="https://portal.newdaycards.com/newpay/login"
    handleClick={() => trackEvent('Login-header button click')}
    passedStyle={style.loginButton}
  >
    Login
  </HeaderButton>
);

const MobileMenuIcon = ({ isOpen, toggleMobileMenuDisplay }) => (
  <button
    id="toggleMenuIcon"
    className="d-lg-none"
    aria-label={isOpen ? 'Close menu' : 'Open menu'}
    css={style.mobileMenuIcon}
    onClick={toggleMobileMenuDisplay}
  >
    {isOpen ? <CloseIcon /> : <BurgerMenu />}
  </button>
);

export const MENU_ITEMS = [
  { label: 'How to apply', to: '/how-to-apply' },
  { label: 'Payment options', to: '/payment-options' },
  { label: 'Shop', to: '/shop' },
  { label: 'Newpay for business', to: '/newpay-for-business' },
  { label: 'Help', to: '/help' },
];

const NavBarLinks = ({ activeLinks, style, toggleMobileMenuDisplay }) => {
  const isLinkActive = (to) => activeLinks.includes(to.replace(/\/#?/, ''));

  return (
    <Fragment>
      {MENU_ITEMS.map(({ label, to }) => (
        <StyledLink
          key={label}
          css={style}
          to={to}
          linkActive={isLinkActive(to)}
          handleClick={() => {
            trackEvent(`${label}- NavBar link click`);
            toggleMobileMenuDisplay && toggleMobileMenuDisplay();
          }}
        >
          {label}
        </StyledLink>
      ))}
    </Fragment>
  );
};

Header.propTypes = {
  activeLinks: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string,
};

export default function Header({ activeLinks, path }) {
  const [scrolled, setScrolled] = useState(false);
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

  const displayNavBar = !path.includes('retrieve-your-application');

  const toggleMobileMenuDisplay = () => {
    setDisplayMobileMenu(!displayMobileMenu);
  };

  useEffect(() => {
    const onScroll = () => {
      const isScrolled = !!window.scrollY;
      setScrolled(isScrolled);
    };

    document.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setScrolled]);

  return (
    <Fragment>
      <div css={style.headerTopBg} />
      <header
        data-cy="header"
        css={style.header}
        className={` ${scrolled ? 'scrolled' : ''}  ${displayMobileMenu ? 'mobile-menu' : ''}`}
      >
        <div css={style.headerItemsWrapper} className="container container-fluid">
          {/* Logo */}
          <LogoLink scrolled={scrolled} closeMenu={() => displayMobileMenu && setDisplayMobileMenu(false)} />

          {/* Desktop Menu -  NavLinks */}
          {displayNavBar && (
            <nav css={style.navbar} className="d-none d-lg-flex">
              <NavBarLinks style={style.desktopLink} activeLinks={activeLinks} />

              <LoginButton />
            </nav>
          )}

          <MobileMenuIcon
            toggleMobileMenuDisplay={toggleMobileMenuDisplay}
            closeMenu={() => displayMobileMenu && setDisplayMobileMenu(false)}
          />
        </div>
      </header>

      {displayMobileMenu && (
        <header css={style.mobileMenuWrapper}>
          <div css={style.mobileHeaderItemsWrapper} className="container container-fluid">
            {/* Logo */}
            <LogoLink isMobile closeMenu={() => displayMobileMenu && setDisplayMobileMenu(false)} />

            {/* Login button */}
            <div css={style.topBarMobile}>
              <LoginButton />

              <MobileMenuIcon isOpen toggleMobileMenuDisplay={toggleMobileMenuDisplay} />
            </div>
          </div>

          <div role="presentation" css={style.divider} />

          <nav id="mobileMenu" className="container container-fluid">
            <NavBarLinks
              style={style.mobileLink}
              toggleMobileMenuDisplay={toggleMobileMenuDisplay}
              activeLinks={activeLinks}
            />
          </nav>
        </header>
      )}
    </Fragment>
  );
}
