import SelectLink from '@components/common/SelectLink/SelectLink';
import { jsx } from '@emotion/react';

import PropTypes from 'prop-types';

import * as style from './HeaderButton.style';

HeaderButton.propTypes = {
  children: PropTypes.string,
  disabled: PropTypes.bool,
  passedStyle: PropTypes.object,
  link: PropTypes.string,
};

export default function HeaderButton({ passedStyle, children, disabled, link, handleClick }) {
  const buttonStyles = [
    style.defaultStyle,
    disabled ? style.disabledLoginStyle : style.hoverStyle,
    passedStyle,
  ];

  const ariaLabel = typeof children === 'string' ? children : '';

  return (
    <SelectLink
      ariaLabel={ariaLabel}
      disabled={disabled}
      linkStyle={buttonStyles}
      to={link}
      handleClick={handleClick}
    >
      {children}
    </SelectLink>
  );
}
