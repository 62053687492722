import { useContext, useEffect } from 'react';

import { ActiveMenuContext } from '@components/common/Layout/Layout';

const useCurrentPageActiveLink = (path) => {
  const { setActiveLinks } = useContext(ActiveMenuContext);

  useEffect(() => {
    setActiveLinks([path.replace(/\//g, '')]);

    return () => setActiveLinks([]);
  }, [setActiveLinks, path]);
};

export default useCurrentPageActiveLink;
