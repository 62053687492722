import SelectLink from '@components/common/SelectLink/SelectLink';
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

StyledLink.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  style: PropTypes.string,
  to: PropTypes.string,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  linkActive: PropTypes.bool,
};

export default function StyledLink({
  children,
  disabled,
  to = '/',
  className = '',
  linkActive,
  handleClick,
}) {
  let aggregateClassName = `d-flex align-items-center ${className}`;

  aggregateClassName = disabled ? `disabled ${aggregateClassName}` : aggregateClassName;

  return (
    <span className={aggregateClassName}>
      <SelectLink disabled={disabled} to={to} handleClick={handleClick}>
        <span className={linkActive ? 'active' : ''}>{children}</span>
      </SelectLink>
    </span>
  );
}
