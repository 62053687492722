const Default = ({ light, height }) => {
  return (
    <svg
      height={height ?? '40'}
      viewBox="0 0 143 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="translate(0 -5.5)"
    >
      <path
        strokeWidth={0}
        fill={light ? '#283237' : '#05e1aa'}
        d="M122.9,0H19.7C8.8,0,0,9,0,20s8.8,20,19.7,20h103.2c10.9,0,19.7-9,19.7-20S133.8,0,122.9,0Z"
      />
      <path
        strokeWidth={0}
        fill={light ? '#05e1aa' : '#283237'}
        d="M30.3,26.7h4.4v-7.9c0-4.1-2.8-6.7-7-6.7s-7.1,2.6-7.1,6.7v7.9h4.4v-7.8c0-1.8,1-2.9,2.7-2.9s2.7,1.1,2.7,2.9v7.8h0ZM46.5,17.6h-5.8c.2-1.3,1.5-2.3,2.9-2.3s2.8.9,2.9,2.3ZM43.7,12.1c-4.4,0-7.4,3.3-7.4,7.5s3.1,7.5,7.8,7.5,4-.5,5.1-1.1v-4.1c-1.4,1-2.8,1.3-4.3,1.4-2.3.1-4.3-1.1-4.2-2.8h9.4c.7-4.7-1.7-8.4-6.5-8.4h0ZM68.5,26.7l5.5-14.4h-4.6l-2.6,8.3-3-8.3h-3.4l-3,8.3-2.6-8.3h-4.6l5.5,14.4h3.2l3.2-7.9,3.1,7.9h3.2ZM84.6,19.5c0,2-1.3,3.3-3.1,3.3s-3.1-1.3-3.1-3.3,1.4-3.3,3.1-3.3,3.1,1.3,3.1,3.3ZM89.4,19.5c0-4.3-3.3-7.5-7.8-7.5s-7.8,3.2-7.8,7.5v12.7h4.4v-7.1c.7,1.2,2.2,1.9,4.1,1.9,4.6,0,7.2-3.3,7.2-7.5h0ZM101.5,19.5c0,2-1.4,3.3-3.1,3.3s-3.1-1.3-3.1-3.3,1.4-3.3,3.1-3.3,3.1,1.3,3.1,3.3ZM101.9,26.7h4.4v-7.2c0-4.3-3.5-7.5-7.9-7.5s-7.8,3.2-7.8,7.5,2.6,7.5,7.2,7.5,3.4-.7,4.1-1.9v1.5ZM122,24.7v-12.4h-4.4v7.8c0,1.8-1.1,3-2.6,3s-2.5-1.2-2.5-3v-7.7h-4.4v8.3c0,4,2.3,6.5,5.7,6.5s3-.4,3.8-1.9c0,1.1,0,2.1-.9,2.9-.8.7-2,1-3,1s-3.3-.4-4.6-1.4v4.1c1.3.7,3.1,1.1,5.1,1.1s3.1-.4,4.3-1.1c1.1-.6,1.9-1.5,2.5-2.7.7-1.4.8-2.7.8-4.3h0Z"
      />
    </svg>
  );
};

export default Default;
