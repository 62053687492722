exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-business-enquiry-jsx": () => import("./../../../src/pages/business-enquiry.jsx" /* webpackChunkName: "component---src-pages-business-enquiry-jsx" */),
  "component---src-pages-business-enquiry-success-jsx": () => import("./../../../src/pages/business-enquiry-success.jsx" /* webpackChunkName: "component---src-pages-business-enquiry-success-jsx" */),
  "component---src-pages-cookie-notice-jsx": () => import("./../../../src/pages/cookie-notice.jsx" /* webpackChunkName: "component---src-pages-cookie-notice-jsx" */),
  "component---src-pages-eligibility-check-terms-jsx": () => import("./../../../src/pages/eligibility-check-terms.jsx" /* webpackChunkName: "component---src-pages-eligibility-check-terms-jsx" */),
  "component---src-pages-help-jsx": () => import("./../../../src/pages/help.jsx" /* webpackChunkName: "component---src-pages-help-jsx" */),
  "component---src-pages-how-to-apply-jsx": () => import("./../../../src/pages/how-to-apply.jsx" /* webpackChunkName: "component---src-pages-how-to-apply-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-newpay-for-business-jsx": () => import("./../../../src/pages/newpay-for-business.jsx" /* webpackChunkName: "component---src-pages-newpay-for-business-jsx" */),
  "component---src-pages-payment-options-jsx": () => import("./../../../src/pages/payment-options.jsx" /* webpackChunkName: "component---src-pages-payment-options-jsx" */),
  "component---src-pages-privacy-notice-jsx": () => import("./../../../src/pages/privacy-notice.jsx" /* webpackChunkName: "component---src-pages-privacy-notice-jsx" */),
  "component---src-pages-privacy-notice-retailers-jsx": () => import("./../../../src/pages/privacy-notice/retailers.jsx" /* webpackChunkName: "component---src-pages-privacy-notice-retailers-jsx" */),
  "component---src-pages-retrieve-your-application-jsx": () => import("./../../../src/pages/retrieve-your-application.jsx" /* webpackChunkName: "component---src-pages-retrieve-your-application-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */)
}

