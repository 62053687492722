import { useCallback, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

const SWIPE_DIRECTION_LEFT = 'left';
const SWIPE_DIRECTION_RIGHT = 'right';

const useCarouselBehavior = (itemsCount) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleIndexUpdate = useCallback(
    (swipeDirection) => {
      if (swipeDirection === SWIPE_DIRECTION_LEFT) {
        if (activeIndex > 0) {
          setActiveIndex(activeIndex - 1);
        } else {
          setActiveIndex(itemsCount - 1);
        }
      }

      if (swipeDirection === SWIPE_DIRECTION_RIGHT) {
        if (activeIndex < itemsCount - 1) {
          setActiveIndex(activeIndex + 1);
        } else {
          setActiveIndex(0);
        }
      }
    },
    [activeIndex, itemsCount],
  );

  const handlers = useSwipeable({
    onSwipedLeft: () => handleIndexUpdate(SWIPE_DIRECTION_RIGHT),
    onSwipedRight: () => handleIndexUpdate(SWIPE_DIRECTION_LEFT),
  });

  return {
    handlers,
    activeIndex,
    setActiveIndex,
  };
};

export default useCarouselBehavior;
