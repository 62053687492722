import { css } from '@emotion/react';
import theme from '@styles/common/theme';

export const footerWrapper = css`
  isolation: isolate;
  padding: 4rem 0;

  color: white;
  background-color: ${theme.grey900};
`;

export const linksWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  margin-bottom: 1.5rem;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const link = css`
  font-size: 22px;
  font-weight: 700;
  color: inherit;
  color: inherit;
  transition: color 0.6s;
  text-decoration: none;
  border-radius: 0.375rem;

  &:focus {
    outline: 1px solid ${theme.white};
  }

  &.disabled {
    color: ${theme.grey600};
    cursor: not-allowed;
  }

  &:not(.disabled):hover:not(:focus) {
    color: ${theme.teal500};
    cursor: pointer;
  }

  &:not(.disabled) a:focus {
    background-color: ${theme.yellow400};
    color: ${theme.grey900};
  }
`;

export const legalWrapper = css`
  font-size: 18px;

  text-align: left;
`;

export const legalTitle = css`
  max-width: 83%;
  margin-bottom: 2rem;

  font-size: 18px;

  @media (max-width: 575px) {
    max-width: 100%;
  }
`;

export const legalFooter = css`
  height: 32px;
  width: 100%;
  margin-top: 4rem;

  font-size: 1rem;

  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: 25px;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const logo = css`
  height: 100%;

  &:focus-visible {
    outline-offset: 4px;
  }

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }
`;
