import { css } from '@emotion/react';
import theme from '@styles/common/theme';

export const defaultStyle = css`
  min-width: 6rem;
  background-color: transparent;
  border: solid 1px ${theme.grey900};
  color: ${theme.grey900};
  border-radius: 0.375rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-weight: bold;
  font-size: 0.938rem;
  font-family: inherit;
  transition: background-color 0.6s, color 0.6s;
  &:focus-visible {
    border: solid 2px ${theme.webkitFocusBlue};
    background-color: ${theme.yellow400};
  }
`;

export const hoverStyle = css`
  &:hover {
    text-decoration: none;
    background-color: ${theme.grey900};
    color: white;
    cursor: pointer;
  }
`;

export const disabledLoginStyle = css`
  border-color: ${theme.grey600};
  color: ${theme.grey600};
  cursor: not-allowed;
`;
