import { ActiveMenuContext } from '@components/common/Layout/Layout.jsx';
import { useContext, useEffect, useRef } from 'react';

const useCurrentSectionActiveLink = (throttleActive = true) => {
  const target = useRef();
  const { setActiveLinks } = useContext(ActiveMenuContext);

  useEffect(() => {
    let timeout = null;
    const updateActiveLinksIfNeeded = (elementScrolledIntoView) => {
      setActiveLinks((currentActiveLinks) => {
        if (elementScrolledIntoView && !currentActiveLinks.includes(target.current.id)) {
          return [target.current.id];
        } else if (!elementScrolledIntoView && currentActiveLinks.includes(target.current.id)) {
          return currentActiveLinks.filter((x) => x !== target.current.id);
        }

        return currentActiveLinks;
      });
    };

    const checkElementPositionAndUpdateActiveLinks = () => {
      if (!target.current) {
        return;
      }
      const elementPosition = target.current.getBoundingClientRect();
      const elementScrolledIntoView = elementPosition.top <= 200 && elementPosition.bottom >= 200;
      updateActiveLinksIfNeeded(elementScrolledIntoView);
    };

    const throttle = (f) => {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null;
          f();
        }, 100);
      }
    };

    const handleScroll = () =>
      throttleActive
        ? throttle(checkElementPositionAndUpdateActiveLinks)
        : checkElementPositionAndUpdateActiveLinks();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      setActiveLinks([]);
    };
  }, []);
  return target;
};

export default useCurrentSectionActiveLink;
