/* eslint-disable react/prop-types */
import { css, Global } from '@emotion/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { createContext, useEffect, useState } from 'react';

import { useScrollBehavior } from '@hooks/index.js';

import Footer from '@components/common/Footer/Footer';
import Header from '@components/common/Header/Header';

import theme from '@styles/common/theme';
import * as style from './Layout.style';

import { CookieConsent } from '@newdaycards/ownbrands-library-cookieconsent';
// imported CSS for CookieConsent banner.
import '@newdaycards/ownbrands-library-cookieconsent/build/main.css';
// Custom styles for cookie banner.
import '../Cookies/cookie-banner.css';

export const ActiveMenuContext = createContext(null);

export function Layout({ children }) {
  const scrollBehavior = useScrollBehavior(children);

  const globalStyles = css`
    body {
      color: ${theme.grey900};
      font-family: 'Wallop', sans-serif;
      background-color: transparent;
      font-weight: 300;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
    }

    a {
      text-decoration: none;
    }

    html {
      scroll-behavior: ${scrollBehavior};
      scroll-padding-top: 4rem;
    }
  `;

  useEffect(() => {
    document.querySelector(':root').style.setProperty('--teal500', theme.teal500);
    document.querySelector(':root').style.setProperty('--grey900', theme.grey900);
    document.querySelector(':root').style.setProperty('--grey40', theme.grey40);

    new CookieConsent().createPopup();
  }, []);

  return (
    <div css={style.fontWrapper}>
      <Global styles={globalStyles} />
      {children}
    </div>
  );
}

export function SharedElementsLayout({ children, path }) {
  const [activeLinks, setActiveLinks] = useState([]);

  const contextValue = {
    activeLinks,
    setActiveLinks,
  };

  return (
    <ActiveMenuContext.Provider value={contextValue}>
      <div css={style.wrapper}>
        <Header activeLinks={activeLinks} path={path} />

        <div css={style.contentWrapper}>
          <main id="main-content" data-cy="main-content" css={style.main}>
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </ActiveMenuContext.Provider>
  );
}
