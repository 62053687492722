import React from 'react';
import { Layout, SharedElementsLayout } from './src/components/common/Layout/Layout';
import appInsights from './src/utils/appinsights-service';

export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout>
      <SharedElementsLayout path={props.uri}>{element}</SharedElementsLayout>
    </Layout>
  );
};

export const onClientEntry = () => {
  appInsights.loadAppInsights();
};

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	const currentPosition = getSavedScrollPosition(location);

	setTimeout(() => {
		window.scrollTo(...(currentPosition || [0, 0]));
	}, 0);

	return false;
};
