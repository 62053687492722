import { useEffect, useRef } from 'react';

const useTrapFocus = () => {
  const overlayContainerRef = useRef();

  useEffect(() => {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const overlayContainer = overlayContainerRef.current;

    const focusableContent = overlayContainer.querySelectorAll(focusableElements);
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    const keepFocusInsideOverlay = (e) => {
      const isTabPressed = e.key === 'Tab' || e.code === 9;

      const movingFocusForwardFromLastElement =
        isTabPressed && !e.shiftKey && document.activeElement === lastFocusableElement;
      const movingFocusBackwardFromFirstElement =
        isTabPressed && e.shiftKey && document.activeElement === firstFocusableElement;

      if (movingFocusBackwardFromFirstElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      } else if (movingFocusForwardFromLastElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', keepFocusInsideOverlay);
    return () => {
      document.removeEventListener('keydown', keepFocusInsideOverlay);
    };
  }, []);
  return overlayContainerRef;
};

export default useTrapFocus;
