import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const getMaxBatchInterval = () => {
  return process.env.MAX_BATCH_INTERVAL ? process.env.MAX_BATCH_INTERVAL : 15000;
};

const appInsightsService = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.INSTRUMENTATION_KEY,
    disableAjaxTracking: true,
    maxBatchInterval: getMaxBatchInterval(),
  },
});

export const trackEvent = async (name, extraInfo = {}) => {
  const formattedName = `newpay.co.uk: ${name}`;
  appInsightsService.trackEvent({
    name: formattedName,
    properties: { origin: window.location.pathname, ...extraInfo },
  });
};

export const trackPageView = async (pageName) => {
  const formattedName = `newpay.co.uk: ${pageName} view`;
  appInsightsService.trackPageView({
    name: formattedName,
  });
};

export default appInsightsService;