import theme from '@styles/common/theme';

const Outlined = ({ height, noStroke, ariaLabel }) => (
  <svg
    display="inline"
    height={height ?? '29'}
    viewBox="0 0 101 29"
    fill={theme.grey900}
    xmlns="http://www.w3.org/2000/svg"
    aria-label={ariaLabel}
  >
    <rect
      x="1.25146"
      y="1.4585"
      width="99.1583"
      height="26.509"
      rx="13.4805"
      fill={theme.grey900}
      stroke={noStroke ? 'none' : 'white'}
    />
    <path
      d="M85.1848 10.0131V12.4301V17.0283V18.7204C85.1848 19.7943 85.1279 20.7454 84.6281 21.7199C84.1851 22.5825 83.6212 23.1809 82.8756 23.6368C81.9679 24.191 80.9126 24.4478 79.8585 24.4478C78.4473 24.4478 77.1632 24.132 76.2894 23.6392V20.7798C77.202 21.4901 78.6204 21.7457 79.4555 21.7457C80.184 21.7457 80.9816 21.5503 81.5504 21.0686C82.2088 20.5107 82.1543 19.8005 82.1543 19.0054C81.5916 20.0708 80.6391 20.3461 79.493 20.3461C77.1427 20.3461 75.5693 18.6307 75.5693 15.8302V10.0131H78.6192V15.4161C78.6192 16.6977 79.3768 17.5456 80.3668 17.5456C81.358 17.5456 82.1337 16.7174 82.1531 15.4554V10.0131H85.1848Z"
      fill={theme.teal500}
    />
    <path
      d="M62.6038 15.0745C62.6038 18.0716 60.7993 20.3596 57.6333 20.3596C56.3201 20.3596 55.2587 19.8976 54.7952 19.0325V24.0276H51.7466L51.7659 15.0745C51.7659 12.0578 54.155 9.78943 57.1855 9.78943C60.3503 9.78943 62.6038 12.0578 62.6038 15.0745ZM59.3397 15.0745C59.3397 13.6552 58.4078 12.7472 57.1843 12.7472C55.9607 12.7472 55.0288 13.654 55.0288 15.0745C55.0288 16.495 55.9607 17.3822 57.1843 17.3822C58.4078 17.3822 59.3397 16.4938 59.3397 15.0745Z"
      fill={theme.teal500}
    />
    <path
      d="M24.8292 14.5228V20.1237H21.8V14.6407C21.8 13.3984 21.0811 12.5899 19.9555 12.5899C18.8288 12.5899 18.1099 13.3984 18.1099 14.6407V20.1224H15.0601V14.5216C15.0601 11.6621 16.9832 9.78821 19.9543 9.78821C22.9074 9.78944 24.8292 11.6634 24.8292 14.5228Z"
      fill={theme.teal500}
    />
    <path
      d="M51.9717 10.0266L48.1448 20.1224H45.9301L43.7359 14.5228L41.5223 20.1224H39.3076L35.4819 10.0266H38.6661L40.473 15.8831L42.5704 10.0266H44.9014L46.9987 15.8634L48.7863 10.0266H51.9717Z"
      fill={theme.teal500}
    />
    <path
      d="M35.5072 15.7049H28.9646C28.9101 16.914 30.2354 17.7447 31.8595 17.6562C32.8967 17.5997 33.8286 17.3736 34.8295 16.6941V19.5535C34.0259 19.9836 33.0008 20.3584 31.2713 20.3584C28.0472 20.3584 25.9111 18.2485 25.9111 15.0733C25.9111 12.1352 27.9698 9.78821 31.0003 9.78821C34.3018 9.78944 36.0119 12.4129 35.5072 15.7049ZM32.9524 13.7142C32.8943 12.7484 31.9237 12.0971 30.9531 12.0971C30.0212 12.0971 29.1026 12.7853 28.9791 13.7142H32.9524Z"
      fill={theme.teal500}
    />
    <path
      d="M68.8909 9.78943C71.9214 9.78943 74.3092 12.0578 74.3092 15.0745L74.3286 20.1224H71.2788V19.0312C70.8165 19.8963 69.7551 20.3584 68.4407 20.3584C65.2747 20.3584 63.4702 18.0716 63.4702 15.0733C63.4714 12.0578 65.7249 9.78943 68.8909 9.78943ZM68.8909 17.3822C70.1145 17.3822 71.0464 16.495 71.0464 15.0745C71.0464 13.654 70.1145 12.7472 68.8909 12.7472C67.6674 12.7472 66.7355 13.654 66.7355 15.0745C66.7343 16.4938 67.6674 17.3822 68.8909 17.3822Z"
      fill={theme.teal500}
    />
  </svg>
);

export default Outlined;
